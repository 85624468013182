import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { RiskFilterValue } from '../../../models/TableViewFilters';
import Checkbox from '../../shared/form-control/Checkbox';
import { RiskRating } from '../../../utils/RiskUtils';
import { FilterProps } from './FilterProps';
import { Option } from '../../Option';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import { RiskRatingKeys } from '../../../models/Risk';

const RiskFilter: FC<FilterProps> = ({ columnConfig, selectedTemplateId }) => {
  const { t } = useTranslation(['table-view', 'common', 'risk']);
  const { filters, setFilters } = useTableViewFilters();

  const filterValue = useMemo(() => {
    return filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as RiskFilterValue | undefined;
  }, [columnConfig.value, filters, selectedTemplateId]);

  const riskOptions = useMemo<Option<RiskRating, boolean>[]>(() => {
    const highRisk = { id: RiskRating.HIGH_RISK, text: t(RiskRatingKeys[RiskRating.HIGH_RISK]), value: false };
    const mediumRisk = { id: RiskRating.MEDIUM_RISK, text: t(RiskRatingKeys[RiskRating.MEDIUM_RISK]), value: false };
    const lowRisk = { id: RiskRating.LOW_RISK, text: t(RiskRatingKeys[RiskRating.LOW_RISK]), value: false };
    const noRisk = { id: RiskRating.NO_RISK, text: t(RiskRatingKeys[RiskRating.NO_RISK]), value: false };

    return [highRisk, mediumRisk, lowRisk, noRisk];
  }, [t]);

  const isSelected = useCallback(
    (option: Option<RiskRating, boolean>) => {
      if (!filterValue) return false;
      return filterValue?.includes(option.id);
    },
    [filterValue],
  );

  const toggleSelectAll = useCallback(
    (selectAll: boolean) => {
      const updatedFilter: RiskFilterValue = selectAll ? riskOptions.map((option) => option.id) : [];
      setFilters(columnConfig, selectedTemplateId, updatedFilter.length > 0 ? updatedFilter : undefined);
    },
    [riskOptions, columnConfig, selectedTemplateId, setFilters],
  );

  const updateFilter = useCallback(
    (option: Option<RiskRating, boolean>, value: boolean) => {
      const updatedFilter: RiskFilterValue = value
        ? [...new Set([...(filterValue || []), option.id])]
        : filterValue?.filter((id) => id !== option.id) || [];
      setFilters(columnConfig, selectedTemplateId, updatedFilter.length > 0 ? updatedFilter : undefined);
    },
    [filterValue, columnConfig, selectedTemplateId, setFilters],
  );

  return (
    <div>
      <div className="text-dpm-14 my-1 flex flex-row justify-between font-medium">
        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(true))} data-cy="select-all">
          {t('common:list.filter.select-all')}
        </span>

        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(false))} data-cy="clear-all">
          {t('common:list.filter.clear-all')}
        </span>
      </div>

      <div className="-mr-4 h-64 w-72 space-y-4 overflow-y-auto">
        {riskOptions.map((option) => {
          return (
            <Checkbox
              key={option.id}
              value={isSelected(option)}
              disabled={option.disabled}
              label={option.text}
              onChange={(value) => updateFilter(option, value)}
              role="menuitem"
            />
          );
        })}
      </div>
    </div>
  );
};

export default RiskFilter;
